import { Dispatch, SetStateAction } from 'react';
import { useAtom } from "jotai";

import { isSmallAtom } from "../atoms";

import { ReactComponent as NextIcon } from "../assets/icons/button_next.svg";
import { INode } from '../atoms/data';

export const StoryCatalog = ({
  filteredData,
  setNodeId,
}:{
  filteredData: INode[],
  setNodeId: (nodeId:string | undefined) => void
}) => {

  const [isSmall] = useAtom(isSmallAtom);
  const itemGrid = 'w-1/2 aspect-w-2 md:w-1/3 md:aspect-w-3 lg:w-1/4 lg:aspect-w-4 xl:w-1/5 xl:aspect-w-5 2xl:w-1/6 2xl:aspect-w-6';

  const btnClass = isSmall ? '' : 'hover:opacity-100 hover:text-opacity-100 opacity-0'

  return <div className='flex flex-wrap w-h'>
    {
      filteredData.map((story, index) => {
        
        const imageSrc = !story.media || story.media.length < 1 ? ''
        : story.media[0].thumb_filename ? story.media[0].thumb_filename
        : story.media[0].filename;
        
        return <div
          className={`${itemGrid} relative aspect-h-1 cursor-pointer`}
          key={index}
          onClick={() => setNodeId(story.id)}
        >
          <div className='absolute w-full h-full object-cover'>
            <img className='object-cover w-full h-full' src={imageSrc}/>
          </div>
          <button className={`transition w-full h-full space-y-3 bg-darkGray bg-opacity-50 ${btnClass} text-white font-bold`}>
            <div className={`${isSmall ? 'text-xl' : 'text-2xl'} p-4`}>{story.title}</div>
            <div className="grid place-content-center">
              {
                isSmall || <div className="text-sm flex tracking-wider">
                  VIEW
                  <NextIcon width='30px' height='20px' stroke="white" fill="none" strokeWidth={2.5}/>
                </div>
              }
            </div>
          </button>
        </div>

      })
    }
  </div>
}