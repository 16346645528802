import _ from 'lodash'
import { BrowserRouter as Router } from 'react-router-dom'
import { DisplayRoute } from './routes'
import { useEffect, useMemo, useState } from 'react'
import { isSmallAtom, isLandscapeAtom } from './atoms'
import {
    popupAtom,
    mediaAtom,
    aboutAtom,
    mapAtom,
    storiesAtom,
    handleMediaData,
    handleAboutData,
    handleMapData,
    handleStoriesData,
} from './atoms/data'
import { useAtom } from 'jotai'
import { mobileWidth } from './constants'
import { Navbar, MobileNavbar, IndigenousPopup, Loader } from './components'

import { isMobile } from 'react-device-detect'
import { Helmet } from 'react-helmet'
import axios from 'axios'

const RenderApp = () => {
    const [isSmall, setIsSmall] = useAtom(isSmallAtom)
    const [, setIsLandscape] = useAtom(isLandscapeAtom)

    useEffect(() => {
        handleResize()
        window.addEventListener(
            'resize',
            _.debounce(() => handleResize(), 10)
        )

        return () => {
            window.removeEventListener(
                'resize',
                _.debounce(() => handleResize(), 10)
            )
        }
    }, [])

    const checkLandscape = () => {
        const landscape = window.innerWidth > window.innerHeight
        setIsLandscape(landscape)
    }

    const handleResize = () => {
        isMobile && checkLandscape()
        setIsSmall(window.innerWidth < mobileWidth)
    }

    return (
        <>
            {isSmall ? <MobileNavbar /> : <Navbar />}
            <DisplayRoute />
        </>
    )
}

function App() {
    const [isSmall] = useAtom(isSmallAtom)
    const [isLandscape] = useAtom(isLandscapeAtom)

    const [, setMediaData] = useAtom(mediaAtom)
    const [, setAboutData] = useAtom(aboutAtom)
    const [, setMapData] = useAtom(mapAtom)
    const [, setStoriesData] = useAtom(storiesAtom)
    const [popupData, setPopupData] = useAtom(popupAtom)

    const [showIndigenousPopup, setShowIndigenousPopup] =
        useState<boolean>(true)

    const [isLoading, setIsLoading] = useState<boolean>(true)

    const RenderMemo = useMemo(() => <RenderApp />, [])

    const getData = async () => {
        try {
            const media = await axios.get(
                'https://lightwell-sandstone-assets.s3.ap-southeast-2.amazonaws.com/media.json'
            )
            const nodes = await axios.get(
                'https://lightwell-sandstone-assets.s3.ap-southeast-2.amazonaws.com/nodes.json'
            )

            const mediaData = await handleMediaData(media.data)
            const aboutData = await handleAboutData(nodes.data.ABOUT, mediaData)
            const mapData = await handleMapData(nodes.data.MAP, mediaData)
            const storiesData = await handleStoriesData(
                nodes.data.STORIES,
                mediaData
            )

            setMediaData(mediaData)
            setAboutData(aboutData)
            setMapData(mapData)
            setStoriesData(storiesData)
            setPopupData(nodes.data.POPUP)
        } catch (err: any) {
            console.log(err)
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getData()
    }, [])

    if (isLoading) return <Loader isLoading />

    return (
        <>
            <Helmet>
                <title>Sandstone Precinct Heritage</title>
                <meta
                    name="description"
                    content="Discover the rich history and heritage interpretation of the Sandstone Precinct."
                />
            </Helmet>
            <Router>{RenderMemo}</Router>
            {showIndigenousPopup && (
                <IndigenousPopup
                    isSmall={isSmall}
                    showModal={showIndigenousPopup}
                    setShowExternal={setShowIndigenousPopup}
                    popupData={popupData}
                />
            )}
            {isMobile && isLandscape && (
                <>
                    <div className="absolute top-0 bg-darkGray w-full h-full grid place-content-center z-50">
                        <div className="text-white w-42 h-5">
                            This site is best viewed in portrait mode.
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default App
