import { Dispatch, SetStateAction } from "react";
import { Modal } from ".";
import parse from 'html-react-parser';
import { ReactComponent as NextIcon } from "../assets/icons/button_next.svg";

export const IndigenousPopup = ({
  showModal,
  setShowExternal,
  isSmall,
  popupData
}: {
  showModal: boolean,
  setShowExternal: Dispatch<SetStateAction<boolean>>
  isSmall: boolean,
  popupData: any;
}) => {

  console.log(popupData);

  return <Modal
    showModal={showModal}
    setShowExternal={setShowExternal}
  >
    <Modal.Display
      showOverlay
      tailwindHeight={
        isSmall ? "w-full h-full" :"h-fit w-shareModal min-h-fit"
      }
      higherModal
      overlayOpacity={50}
    >
      <div
        className={`${isSmall ? 'grid' : ''} w-full h-full leading-relaxed tracking-relaxed text-base lg:text-md px-8 py-12 md:px-20 overflow-y-auto`}
      >
        <div>
          <div className={`font-bold ${isSmall ? 'text-3xl mb-6' : 'text-4xl mb-8'} tracking-wider`}>
            {popupData && popupData.length > 0 && popupData[0].title}
          </div>
          {popupData && popupData.length > 0 ? parse(popupData[0].description) : ''}
          <div className='transition duration-500 text-btnTextColor hover:text-darkGray font-bold flex cursor-pointer mt-6' onClick={() => setShowExternal(false)}>
            CONTINUE
            <NextIcon
              width='36px'
              height='23px'
              // stroke="#8C6929"
              style={{
                stroke: 'currentColor'
              }}
              fill="none"
              strokeWidth={2}
            />
          </div>
        </div>
      </div>
    </Modal.Display>
  </Modal>
}
