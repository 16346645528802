
import { NavLink } from "react-router-dom";

interface INavButtonText {
	children: string | React.ReactNode;
	to: string;
	location?: string;
	mobile?: boolean;
	darkBg?: boolean;
}

interface INavButtonProps extends INavButtonText {
  onClick?: any;
	target?: string;
}

export const NavText = ({
	location,
	mobile,
	children,
	to,
	darkBg,
}: INavButtonText) => {
	const baseTextColor = darkBg ? 'text-btnTextLight' : 'text-darkGray';
	const activeTextColor = darkBg ? 'text-white' :'text-btnTextActive';
	const textColor = '.' + location !== to ? baseTextColor : activeTextColor;
	const textSize = mobile ? ' text-xl py-4 px-5' :  'px-3';
	return <div
		className={`transition-colors duration-500 uppercase tracking-wider ${textColor} ${textSize} hover:${activeTextColor}`}
		style={{
			fontSize: mobile ? 'inherit' : '15px',
			fontWeight: '700'
		}}
	>
		{children}
	</div>
}

export const NavButton = ({
	to,
  onClick,
	target,
	...restProps
}: INavButtonProps) => {
	return <NavLink
    to={to} 
    onClick={() => {
      if (onClick) onClick()
    }}
		target={target}
  >
		<NavText to={to} {...restProps}/>
	</NavLink>
}

export const ExternalNavButton = ({
	to,
  onClick,
	target,
	...restProps
}: INavButtonProps) => {
	return <a href={to} target={target}>
		<NavText to={to} {...restProps}/>
	</a>
}