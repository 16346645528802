import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Map from '../views/Map';
import Trials from '../views/Stories';
import About from '../views/About';

const DisplayRoute:React.FC = () => {
  return (
    <Routes>
      <Route path='/' element={<Map/>} />
      <Route path='/map' element={<Map/>}/>
      <Route path='/map/:id' element={<Map/>}/>
      <Route path='/stories' element={<Trials/>}/>
      <Route path='/stories/:id' element={<Trials/>}/>
      <Route path='/about' element={<About/>} />
    </Routes>
  )
};

export { DisplayRoute };