import React, { useEffect } from "react";
import { useAtom } from "jotai";

import { Content } from "../components";
import { isMapAtom, isSmallAtom } from "../atoms";

import { aboutAtom } from "../atoms/data";
import parse from 'html-react-parser'

const About:React.FC = () => {
  
  const [isSmall] = useAtom(isSmallAtom);
  const [, setIsMap] = useAtom(isMapAtom);
  const [aboutData] = useAtom(aboutAtom);

  useEffect(() => {
    setIsMap(false);
  }, [])

	return <div className="w-full min-h-full bg-darkGray"> 
    <Content>
      <div className={`animate-fadeinSlow py-24 px-8 lg:px-20 ${!isSmall ? 'flex justify-around space-x-8' : 'space-y-12'}`}>
        <div id='text' className={`${isSmall ? 'w-full' : 'w-1/2'} px-2 space-y-8`}>
          <div id='title' className='font-bold text-4xl text-white leading-[2.7rem]'>
            {parse(aboutData.title)}
          </div>
          <div id='body' className='text-btnTextLight leading-7'>
            {parse(aboutData.description)}
          </div>
        </div>
        <div id='images' className={`${isSmall ? 'w-full' : 'w-1/2'}`}>
          {
            aboutData.media.map((item: any) => {
              return <div className='pb-8 space-y-4 text-btnTextLight'>
                <img className='' src={item.filename}/>
                <div className='text-sm'>
                  {parse(item.caption)}
                </div>
                <div className='text-xs'>
                  {parse(item.credit)}
                </div>
              </div>
            })
          }
        </div>
      </div>
    </Content>
  </div>
}

export default About;