import React, {useState, useEffect } from "react";
import {
  MapZoomButton,
  Loader,
  ItemModal,
} from "../components";
import _ from "lodash";
import { useAtom } from "jotai";
import { useParams, useNavigate, useLocation } from "react-router-dom";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { mapAtom }  from "../atoms/data";
import { INode } from '../atoms/data';
import { isMapAtom, isSmallAtom } from "../atoms";

// import mapImage from "../assets/maps/map_3840px_prog.png"
import mapImage from "../assets/maps/map_extended.png"

import { ZoomInIcon, ZoomOutIcon } from "../assets";
import { ReactComponent as NextIcon } from "../assets/icons/button_next.svg";

const mapWidth = 3840;
const mapHeight = 3585;

interface IMarkerProps {
  xpos: number,
  ypos: number,
  title: string,
  onClick: any, // function
  isSmall?: boolean
}

const TitleButton:React.FC<IMarkerProps> = ({
  xpos,
  ypos,
  title,
  onClick,
  isSmall,
}) => {

  const style = { transform: `translate(${xpos}px, ${ypos}px)` }
  const className = `transition-opacity w-0 h-0 relative z-10 pointer-events-auto`
  const btnBaseClass = 'animate-fadeinMapControls transition-colors bg-darkGray hover:bg-darkGrayHover p-3 text-white text-sm tracking-wide uppercase font-bold';

  return <div className={className} style={style}>
    {
      isSmall ? <button
        className={`absolute w-fit transition-colors duration-500 ${btnBaseClass} flex`}
        style={{
          left: '50%',
          transform: 'translate(-50%, 0)'
        }}
        onClick={() => onClick()}
      >
        <div className="w-fit h-4 grid whitespace-nowrap text-xs content-center">{title}</div>
        <NextIcon width='24px' height='23px' stroke="white" fill="none" className='-my-1' strokeWidth={2}/>
      </button> : <button
        className={`w-fit transition-colors duration-500 ${btnBaseClass} flex`}
        style={{
          left: '50%',
          transform: 'translate(-50%, 0)'
        }}
        onClick={() => onClick()}
      >
        <div className="w-fit h-6 grid whitespace-nowrap content-center">{title}</div>
        <NextIcon width='36px' height='23px' stroke="white" fill="none" strokeWidth={2}/>
      </button>
    }
  </div>
}


const Map:React.FC = () => {
  
  const wrapperStyle = {
    width: '100%',
    height: '100%',
  }

  const { id } = useParams();
  const navigate = useNavigate();
  window.onpopstate = () => {
    if (!id && showModal) {
      setShowModal(false);
      setSelectedNodeId(undefined);
    } else if (id && !showModal) {
      setSelectedNodeId(id);
    }
  }

  const [mapData] = useAtom(mapAtom);

  const [minScale, setMinScale] = useState(1);
  const [displaySize, setDisplaySize] = useState([1024, 768]);
  const [initUpdate, setInitUpdate] = useState<boolean>(false);
  const [mapLoaded, setMapLoaded] = useState(false);
  // const [nodeData, setNodeData] = useState<any>(null);
  const [selectedNodeId, setSelectedNodeId] = useState<string|undefined>(id);
  const [showModal, setShowModal] = useState<boolean>(id !== undefined);
  const [target, setTarget] = useState<number[]|null>(null);
  const [mapFadedIn, setMapFadedIn] = useState(false);

  const [isSmall] = useAtom(isSmallAtom);
  const [, setIsMap] = useAtom(isMapAtom);

  const getMinScale = () => {
    const mapRatio = mapWidth/mapHeight;
    const container = document.getElementById('container');
    const wd = container?.clientWidth || 1;
    const ht = container?.clientHeight || 1;
    const ratio = wd/ht;
    const minScale = ratio < mapRatio ? ht/mapHeight : wd/mapWidth;
    return {
      minScale,
      wd,
      ht
    };
  }

  const fetchData = async () => {
    try {
      const image = new Image();
      image.onload = () => {
        setMapLoaded(true);
        setInitUpdate(true);
      }
      image.src = mapImage;
    } catch(error) {
      console.error(error);
    }
  }

  const setModalState = (show:boolean) => {
    setShowModal(show);
    if (!show) navigate(`/map`);
  }

  const handleMarkerClicked = (index:number, nodeId: string, xpos: number, ypos: number) => {
    const x = xpos + displaySize[0]*0.5;
    const y = ypos + displaySize[1]*0.5;
    setTarget([x, y]);
    // setNodeData(node);
    setSelectedNodeId(nodeId);
    setShowModal(true);
    navigate(`/map/${nodeId}`);
  }

  const refreshMap = () => {
    const newDetails = getMinScale();
    setDisplaySize([newDetails.wd, newDetails.ht]);
    setMinScale(newDetails.minScale);
    setInitUpdate(true);
  }

  useEffect(() => {
    refreshMap();
  }, [mapLoaded])

  useEffect(() => {
    if (!showModal) setSelectedNodeId(undefined);
  }, [showModal])

  useEffect(() => {
    setIsMap(true);
    fetchData();
    window.addEventListener('resize', _.debounce(() => refreshMap(), 100));
    return (() => {
      window.removeEventListener('resize', _.debounce(() => refreshMap(), 100));
    })
  }, [])

  const isLoading = !mapData;

  const currentNode = selectedNodeId ? mapData.find((node:INode) => {
    return String(node.id) === String(selectedNodeId)
  }) : null;

  return <React.Fragment>
    <div className='w-full h-full' id='container'>
      <TransformWrapper
        initialScale={minScale}
        initialPositionX={-(mapWidth*minScale - displaySize[0])/2}
        initialPositionY={-(mapHeight*minScale - displaySize[1])/2}
        minScale={minScale}
        maxScale={1}
      >
        {
          ({
            setTransform,
            zoomIn,
            zoomOut,
            resetTransform,
            instance
          }) => {

            const xpos = instance.transformState.positionX;
            const ypos = instance.transformState.positionY;
            const curScale = instance.transformState.scale;
            const isMinScale = instance.transformState.scale === minScale;
            const isMaxScale = instance.transformState.scale === 1;

            if (initUpdate) {
              setInitUpdate(false);
              resetTransform();
            } else if (target !== null) {
              const mapImage = document.getElementById('mapImage');
              const wd = mapImage?.clientWidth || 0;
              const ht = mapImage?.clientHeight || 0;
              const xbound = displaySize[0] - wd*curScale;
              const ybound = displaySize[1] - ht*curScale;
              const newxpos = _.clamp(target[0], xbound, 0);
              const newypos = _.clamp(target[1], ybound, 0);
              setTransform(newxpos, newypos, curScale);
              setTarget(null);
            }

            return(
              <div className='w-full h-full relative'>
                <TransformComponent wrapperStyle={wrapperStyle}>
                  {
                    !isLoading && <img
                      id='mapImage'
                      src={mapImage}
                      alt="Map of the Beechworth Historic Precinct"
                      className="pointer-events-none animate-fadeinMap"
                      style={{
                        maxWidth: '3840px'
                      }}
                      onAnimationEnd={() => setMapFadedIn(true)}
                    />
                  }
                </TransformComponent>
                <div id='markers' className="absolute z-10 left-0 top-0 w-full h-full pointer-events-none overflow-hidden">
                  <Loader isLoading={!mapLoaded} >
                    {
                      mapData && mapData.map((d:any, index:number) => {
                        const markerX = (d.position[0]/100)*mapWidth*curScale;
                        const markerY = (d.position[1]/100)*mapHeight*curScale;
                        const translatedX = markerX + xpos;
                        const translatedY = markerY + ypos;
                        return <TitleButton
                          key={index}
                          xpos = {translatedX}
                          ypos = {translatedY}
                          title={d.title}
                          onClick={() => handleMarkerClicked(index, d.id, -markerX, -markerY)}
                          isSmall={isSmall}
                        />
                      })
                    }
                  </Loader>
                </div>
                <div id='interface' className="absolute inset-y-0 right-0 h-full pointer-events-none z-20">
                  {
                    !isLoading && 
                    <div className="h-full flex flex-wrap place-content-center p-4 animate-fadeinMapControls ">
                      <div id='zoomButtons' className='space-y-2 pointer-events-auto'>
                        <MapZoomButton
                          disabled={isMaxScale}
                          onClick={() => zoomIn(0.5, 600)}
                        >
                          <ZoomInIcon width='50px' height='50px' fill="white" />
                        </MapZoomButton>
                        <MapZoomButton
                          disabled={isMinScale}
                          onClick={() => zoomOut(0.5, 600)}
                        >
                          <ZoomOutIcon width='50px' height='50px' fill="white" />
                        </MapZoomButton>
                      </div>
                    </div>
                  }
                </div>
              </div>
            )
          }
        }
      </TransformWrapper>
    </div>
    {
      showModal && <ItemModal
        data={currentNode}
        isSmall={isSmall}
        setShow={setModalState}
        show={showModal}
        isMapModal
      />
    }
  </React.Fragment>
}

export default Map;