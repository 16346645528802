import { NONAME } from 'dns';
import parse from 'html-react-parser';
import { useEffect, useState } from 'react';

export const ItemTextDesktop = ({
  text
}:{
  text: string
}) => {

  const [hasScroll, setHasScroll] = useState(false);

  useEffect(() => {
    const containerElement = document.getElementById('containerElement');
    const bodyElement = document.getElementById('bodyElement')
    if (bodyElement && containerElement)
      setHasScroll(bodyElement.clientHeight > containerElement.clientHeight);
  }, [])

  return <div
    className="w-full flex-grow max-h-modal relative"
  >
    <div
      id='scrollGradientTop'
      className={`absolute w-full h-6 z-10`}
    >
      <div className='w-full h-full bg-gradient-to-b via-bgSolid from-bgSolid'/>
    </div> 
    <div
      id='scrollGradientBot'
      className={`absolute w-full h-6 bottom-0 z-10`}
    >
      <div className='w-full h-full bg-gradient-to-t via-bgSolid from-bgSolid'/>
    </div> 
    <div
      id="containerElement"
      className="noscrollcontainer w-full h-auto max-h-modal overflow-y-auto relative"
    >
      <div id='bodyElement' className='w-full h-full pr-2 py-5 leading-7'>
        {parse(text)}
      </div>
    </div>
  </div>
}