import _ from 'lodash';
import { atom, useAtom } from 'jotai';
import { mobileWidth } from '../constants';

export interface IMediaItem {
  filename: string;
  thumb_filename?: string;
  node: string;
  caption?: string;
  credit?: string;
  thumb?: HTMLImageElement;
}

export interface INode {
  type: string,
  title: string,
  location: string,
  description: string,
  media?: IMediaItem[],
  id?: string,
  position?: string,
}

const IMAGE_URL = 'https://lightwell-sandstone-assets.s3.ap-southeast-2.amazonaws.com/Stories/';
const THUMB_URL = IMAGE_URL + 'thumbnail/';

// const storyNodes = nodeJsonData.STORIES;
// const mapNodes = nodeJsonData.MAP;
// const aboutNodes = nodeJsonData.ABOUT;

export const mediaAtom = atom<IMediaItem[]>([]);
export const aboutAtom = atom<any>({});
export const mapAtom = atom<any[]>([]);
export const storiesAtom = atom<any[]>([]);
export const popupAtom = atom<any[]>([]);


export const handleMediaData = async (mediaData: any[]) => mediaData.map((mediaItem:IMediaItem, index:number) => {
  const thumb = new Image();
  return {
    ...mediaItem,
    filename: `${IMAGE_URL}${mediaItem.filename}.jpg`,
    thumb_filename: `${THUMB_URL}${mediaItem.thumb_filename}.jpg`,
    thumb: thumb
  }
})

export const handleAboutData = async (aboutData: any[], mediaData: IMediaItem[]) => aboutData.reduce((obj:any, node:INode) => {
  return {
    ...node,
    id: _.replace(node.title.toLowerCase(), / /g, '_'),
    media: mediaData.reduce((arr:IMediaItem[], item:IMediaItem) => {
      if (item.node !== node.title) return arr;
      return [...arr, item];
    }, []),
    type: ''
  }
}, {})

export const handleMapData = async (mapData: any[], mediaData: IMediaItem[]) => mapData.map((node:INode) => {
  return {
    ...node,
    id: _.replace(node.title.toLowerCase(), / /g, '_'),
    position: _.split(node.position,',').map(pos => _.toString(pos)),
    media: mediaData.reduce((arr:IMediaItem[], item:IMediaItem) => {
      if (item.node !== node.title) return arr;
      return [...arr, item];
    }, [])
  }
})

export const handleStoriesData = async (storiesData: any[], mediaData: IMediaItem[]) => storiesData.map((node:INode) => {
  return {
    ...node,
    id: _.replace(node.title.toLowerCase(), / /g, '_'),
    media: mediaData.reduce((arr:IMediaItem[], item:IMediaItem) => {
      if (item.node !== node.title) return arr;
      return [...arr, item];
    }, [])
  }
})


// temp media hack
// export const media:IMediaItem[] = mediaJsonData.map((mediaItem:IMediaItem, index:number) => {
//   const thumb = new Image();
//   return {
//     ...mediaItem,
//     filename: `${IMAGE_URL}${mediaItem.filename}.jpg`,
//     thumb_filename: `${THUMB_URL}${mediaItem.thumb_filename}.jpg`,
//     thumb: thumb
//   }
// })

// export const aboutData = nodeJsonData.ABOUT.reduce((obj:any, node:INode) => {
//   return {
//     ...node,
//     id: _.replace(node.title.toLowerCase(), / /g, '_'),
//     media: media.reduce((arr:IMediaItem[], item:IMediaItem) => {
//       if (item.node !== node.title) return arr;
//       return [...arr, item];
//     }, []),
//     type: ''
//   }
// }, {})

// export const mapData = nodeJsonData.MAP.map((node:INode) => {
//   return {
//     ...node,
//     id: _.replace(node.title.toLowerCase(), / /g, '_'),
//     position: _.split(node.position,',').map(pos => _.toString(pos)),
//     media: media.reduce((arr:IMediaItem[], item:IMediaItem) => {
//       if (item.node !== node.title) return arr;
//       return [...arr, item];
//     }, [])
//   }
// })

// export const storiesData = nodeJsonData.STORIES.map((node:INode) => {
//   return {
//     ...node,
//     id: _.replace(node.title.toLowerCase(), / /g, '_'),
//     media: media.reduce((arr:IMediaItem[], item:IMediaItem) => {
//       if (item.node !== node.title) return arr;
//       return [...arr, item];
//     }, [])
//   }
// })

// export const popupData = nodeJsonData.POPUP;



// export { media, storiesData, mapData, aboutData };