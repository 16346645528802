import React, { ReactNode } from 'react';
import { useAtom } from 'jotai';

import { isSmallAtom } from '../atoms';
import * as constants from '../constants';

const Content:React.FC = ({
  children,
  className
}: {
  className?: string,
  children?: ReactNode,
}) => {
  const [isSmall] = useAtom(isSmallAtom);
  const style = isSmall ? constants.mainViewportMobileStyle : constants.mainViewportDesktopStyle;

  return <React.Fragment>
    <div style={style} className={`z-0 ${className}`}>
      {children}
    </div>
  </React.Fragment>
}

export { Content };