import _ from "lodash";
import { NavButton, NavText } from "./NavButton";
import { useState } from "react";
import { useAtom } from "jotai";
import { NavLink, useLocation } from "react-router-dom";
import { isMapAtom } from "../atoms";

import { ReactComponent as CloseIcon } from '../assets/icons/button_close.svg'
import {
  LogoMobile,
  MobileMenu,
  MobileArrow,
  WebsiteTitle
} from "../assets";

export const MobileNavbar:React.FC = () => {

	const [isMap] = useAtom(isMapAtom);
  const [showMenu, setShowMenu] = useState(false);
  
  const menuStyle = {
    height: 'calc(100vh - 3.5rem)',
    width: '100%',
    paddingTop: '5%',
    paddingLeft: '12%'
  }

	const location = useLocation();

	const hoverDiffClass = isMap ? 'bg-bgTransparent' : 'bg-darkGray';
	const mainDivClass = `${hoverDiffClass} absolute transition duration-200 flex w-full py-5 z-10 h-20`

	const baseColor = !isMap || showMenu ? 'text-white' : 'text-darkGray'
	const logoColor = isMap ? '#333333' : '#dac59d';

  const MobileNavText = ({
    text
  }:{
    text:string
  }) => <div className='flex justify-between'>
    <div className='h-4 p'>{text}</div>
    <MobileArrow fill='currentColor' width='28px' height='28px'/>
  </div>

  return <>
		<div className="relative w-full z-30">
      <div
        className={`absolute transition w-full h-screen bg-darkGray flex flex-wrap content-center ${showMenu ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
      >
        <div className='w-full'>
          <NavButton darkBg to='./' location={location.pathname} mobile onClick={() => setShowMenu(false)}>
            <MobileNavText text='Map'/>
          </NavButton>
          <NavButton darkBg to='./stories' location={location.pathname} mobile onClick={() => setShowMenu(false)}>
            <MobileNavText text='Stories'/>
          </NavButton>
          <NavButton darkBg to='./about' location={location.pathname} mobile onClick={() => setShowMenu(false)}>
            <MobileNavText text='About'/>
          </NavButton>
          <a href='https://capellahotels.com/en/capella-sydney' target='top_'>
            <NavText to={''} darkBg mobile >
              <MobileNavText text='Capella Sydney'/>
            </NavText>
          </a>
        </div>
      </div>
			<div className={mainDivClass}>
        <div className='flex flex-wrap content-center w-1/4 pl-4'>
          <a href='https://capellahotels.com/en/capella-sydney' target='top_'>
            <div className={`transition duration-500 flex flex-wrap ${baseColor} font-bold uppercase items-center hover:text-btnTextHoverColor`}>
              <LogoMobile fill='currentColor' width='40px'/> 
            </div>
          </a>
        </div>
				<div className="flex-grow w-1/2">
				<div className="font-bold flex flex-wrap flex-grow justify-center">
					<WebsiteTitle fill={logoColor} width='200px'/>
				</div>
				</div>
        <div className='flex flex-wrap content-center w-1/4 justify-end'>
          <button
            onClick={() => setShowMenu(!showMenu)}
            className={`flex ${baseColor} hover:text-gray-500 items-center pr-4`}
          >
            { 
              !showMenu ? <MobileMenu fill='currentColor' strokeWidth={0} width='38px' height='38px'/>
              : <CloseIcon stroke='currentColor' strokeWidth={1.5} width='38px' height='38px'/>
            }
          </button>
        </div>
			</div>
		</div>
  </>
}