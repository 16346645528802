
import { Dispatch, SetStateAction, useMemo, useState } from "react";
import {
  Modal,
  NavButton,
  ZoomItem
} from "./";
import { ItemTextDesktop } from "./";
import MediaCarousel from "./MediaCarousel";

import { preSelectedFilterAtom } from "../atoms";
import { useAtom } from "jotai";

import { ReactComponent as NextIcon } from "../assets/icons/button_next.svg";

import parse from 'html-react-parser';


const MobileModal = ({
  data,
  isSmall,
  setCurItem,
  openZoomModal,
  curItem,
  isMapModal,
  updateFilter
}:{
  data: any,
  isSmall: boolean,
  setCurItem: Dispatch<SetStateAction<number>>,
  openZoomModal: any,
  curItem: number,
  isMapModal: boolean,
  updateFilter: (item:string) => void,
}) => <>
  <div id='carousel' className='w-full h-2/5'>
    <MediaCarousel
      media={data.media}
      // curItem={curItem}
      setCurItemExternal={setCurItem}
      tailwindWidth='w-full h-full'
      isSmall={isSmall || false}
      setZoomItem={openZoomModal}
      hasScroll={false}
    />
  </div>
  <div className='w-full px-8 mt-6 space-y-4'>
    <div className={`space-y-2`}>
      <div className='text-sm leading-5'>{parse(data.media[curItem]?.caption || '')}</div>
      <div className='text-xs'>{parse(data.media[curItem]?.credit || '')}</div>
    </div>
    <div id='title' className='font-bold text-3xl'>
      {data.title}
    </div>
    <div id='body' className='text-sm leading-7'>
      {parse(data.description)}
    </div>
    {
      isMapModal && <div id='link' className='-mx-3'>
        <NavButton
          to='../../stories'
          onClick={() => updateFilter(data.title)}
        >
          <div className='text-btnTextColor text-buttonSize flex'>
            VIEW MORE STORIES
            <NextIcon width='36px' height='23px' stroke="#8C6929" fill="none" strokeWidth={2}/>
          </div>
        </NavButton>
      </div>
    }
  </div>
</>

export const ItemModal = ({
  show,
  setShow,
  isSmall,
  data,
  isMapModal
}:{
  show: boolean,
  setShow: any,
  isSmall?: boolean,
  data: any
  isMapModal: boolean
}) => {

  const [curItem, setCurItem] = useState<number>(0);
  const [zoomItem, setZoomItem] = useState<number|null>(null);
  const [showZoom, setShowZoom] = useState<boolean>(false);
  const [, setPreSelectedFilter] = useAtom(preSelectedFilterAtom);

  const openZoomModal = (item: number|null) => {
    setZoomItem(item);
    setShowZoom(item !== null);
  }

  const hideZoom = (state: boolean) => {
    setShowZoom(state);
    setZoomItem(null);
  }

  const updateFilter = (title: string) => {
    setPreSelectedFilter(title || null);
  }

  const DesktopModal = () => <div className="static grid h-full content-center relative animate-fadeinSlow">
    <div className='py-24 px-24 grid grid-cols-2 gap-16'>
      <div id='carousel' className='max-h-fit order-last'>
        <MediaCarousel
          media={data.media}
          // curItem={curItem}
          setCurItemExternal={setCurItem}
          tailwindWidth='w-full max-h-fit'
          isSmall={isSmall || false}
          setZoomItem={openZoomModal}
          hasScroll={false}
        />
      </div>
      <div id='text' className='flex flex-col gap-4'>
        <div id='title' className='font-bold text-4xl leading-10'>
          {data.title}
        </div>
        <ItemTextDesktop text={data.description} />
        {
          isMapModal && <div id='link' className='-mx-3'>
            <NavButton
              to='../../stories'
              onClick={() => updateFilter(data.title)}
            >
              <div className='relative transition duration-500 text-btnTextColor hover:text-darkGray text-buttonSize flex'>
                VIEW MORE STORIES
                <NextIcon
                  width='36px'
                  height='23px'
                  style={{
                    stroke: 'currentColor'
                  }}
                  fill="none"
                  strokeWidth={2}
                />
              </div>
            </NavButton>
          </div>
        }
      </div>
    </div>
  </div>

  const ZoomModal = ({
    zoomIndex
  }:{
    zoomIndex: number
  }) => <div className='w-full h-full'>
    <div className="w-full h-full absolute inset-x-0 bottom-0">
      <ZoomItem
        src={data.media[zoomIndex].filename}
        caption={data.media[zoomIndex].caption}
        credit={data.media[zoomIndex].credit}
      />
    </div>
  </div>

  const RenderDesktop = useMemo(() => <DesktopModal/>, []);

  return <>
    <Modal setShowExternal={hideZoom} showModal={showZoom}>
      <Modal.Display
        tailwindHeight="h-full"
        tailwindWidth="w-full"
        higherModal
        showOverlay
        noBackground
      >
        { zoomItem !== null && <ZoomModal zoomIndex={zoomItem}/> }
      </Modal.Display>
    </Modal>
    <Modal setShowExternal={setShow} showModal={show}>
      <Modal.Display tailwindHeight="h-full" tailwindWidth="w-full">
        {
          isSmall ? <MobileModal
            data={data}
            isSmall={isSmall}
            setCurItem={setCurItem}
            openZoomModal={openZoomModal}
            curItem={curItem}
            isMapModal={isMapModal}
            updateFilter={updateFilter}
          /> : RenderDesktop
        }
      </Modal.Display>
    </Modal>
  </>
}