import { useState, Dispatch, SetStateAction } from "react";
import Carousel from "nuka-carousel";

import {
  CircleButton,
  Loader,
} from "./";

import { ReactComponent as NextIcon } from '../assets/icons/button_next.svg';
import { HiResZoom } from "../assets";
// import { ReactComponent as CloseIcon } from '../assets/icons/button_close.svg'
import { ReactComponent as PlayButton } from '../assets/icons/button_play.svg'

import parse from 'html-react-parser';

const MediaItem = ({
  src,
  addBottomGradient,
  isSmall,
  alt
}:{
  src: string,
  addBottomGradient: boolean,
  isSmall: boolean
  alt: string,
}) => {
  
  const [mediaLoaded, setMediaLoaded] = useState(false);
  const gradientStyle = {
    background: 'linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 80%, rgba(0,0,0,0.7) 100%)'
  }

  return <>
    <div id='mediaItemContainer' className={`relative w-full h-full`}>
      { mediaLoaded || <Loader black isLoading/> }
      <img
        src={src}
        className={`w-full h-full object-cover relative`}
        onLoad={() => setMediaLoaded(true)}
        alt={alt}
      />
      {
        addBottomGradient &&
        <div className={`w-full h-full absolute top-0 pointer-events-none`} style={gradientStyle} />
      }
    </div>
  </>
}

const ArrowButton = ({
  isLeft,
  onClick,
  scrollShift,
  display,
  isSmall=false
}:{
  isLeft?:boolean;
  onClick: any;
  scrollShift?: boolean;
  display: boolean;
  isSmall?:boolean;
}) => {
  const directionClasses = isLeft ? 'transform rotate-180 ml-4' : scrollShift ? 'mr-6 ' : 'mr-4 ';
  const opacity = !display ? 'opacity-0 pointer-events-none' : 'opacity-100 pointer-events-auto'
  
  return <CircleButton
    className={`transition-opacity ${opacity} pr-3 ${directionClasses}` }
    onClick={onClick}
  >
    <NextIcon width='36px' height='26px' stroke="white" fill="none" strokeWidth={2}/>
  </CircleButton>
}

const MediaCarousel = ({
  media,
  setZoomItem,
  tailwindWidth,
  isSmall,
  // curItem,
  setCurItemExternal,
  hasScroll,
}:{
  media:any[],
  // curItem: number,
  setCurItemExternal?: Dispatch<SetStateAction<number>>,
  setZoomItem: (zoomItem:number|null) => void,
  tailwindWidth: string,
  isSmall: boolean,
  hasScroll: boolean
}) => {

  const [curItem, setCurItem] = useState(0);

  const single = media.length < 2;

  const config = {
    pagingDotsStyle: {
      fill: 'white',
      padding: '0.4rem',
      transform: 'scale(1.5)',
      zIndex: 10
    }
  }

  const containerClass = isSmall ? 'relative w-full h-full'
    : tailwindWidth + ' relative z-10';

  const curItemChanged = (index:number) => {
    setCurItem(index);
    if (setCurItemExternal) setCurItemExternal(index);
  }

  return <>
    <div className={containerClass}>
      <Carousel
        width={'100%'}
        swiping={!single}
        dragging={!single}
        afterSlide={index => curItemChanged(index)}
        slideIndex={curItem}
        renderCenterRightControls={single ? null : ({ nextSlide }) => (
          <ArrowButton
            onClick={nextSlide}
            scrollShift={hasScroll}
            isLeft={false}
            display={curItem < media.length-1}
          />
        )}
        renderCenterLeftControls={single ? null : ({ previousSlide }) => (
          <ArrowButton
            onClick={previousSlide} 
            isLeft
            display={curItem > 0}
          />
        )}
        withoutControls={single}
        defaultControlsConfig={config}
      >
        {
          media.map((item, index) => <MediaItem
            key={index}
            src={item.thumb_filename}
            addBottomGradient={media.length > 1}
            isSmall={isSmall}
            alt={item.caption}
          />)
        }
      </Carousel>
      <CircleButton
        className={`absolute ${hasScroll ? 'mr-6' : 'mr-4'} mb-4 right-0 bottom-0`}
        onClick={() => {
          setZoomItem(curItem);
        }}
      >
        <HiResZoom width='40px' height='40px' fill="white" strokeWidth={1.25}/>
      </CircleButton>
      {
        !isSmall && <div className={`absolute top-full left-0 w-full pt-4 space-y-2`}>
          <div className='text-sm'>{parse(media[curItem]?.caption || '')}</div>
          <div className='text-xs'>{parse(media[curItem]?.credit || '')}</div>
        </div>
      }
    </div>
  </>
}

export default MediaCarousel;