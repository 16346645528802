import React from "react";
import { LinkProps, NavLink } from "react-router-dom";

export interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  readonly className?: string;
  readonly whiteBg?: boolean;
}

const btnClass = 'px-textBtn transition py-3 font-medium text-white bg-btnBgColor hover:bg-btnBgHover flex';

const Button:React.FC<IButtonProps> = ({
  children,
  ...restProps
}) => {
  return (
    <button
      className={btnClass}
      {...restProps}
    >
      { children }
    </button>
  )
}

const NavLinkButton:React.FC<IButtonProps & LinkProps> = ({
  children,
  ...restProps
}) => {
  return (
    <div className='flex justify-center items-center w-max'>
      <NavLink
        className={btnClass}
        {...restProps}
      >
        { children }
      </NavLink>
    </div>
  )
}

const LinkButton:React.FC<IButtonProps & { to: string, target?: string }> = ({
  children,
  to,
  target,
  ...restProps
}) => {
  return <a href={to} target={target}>
    <button
      className={btnClass}
      {...restProps}
    >
      <div className='flex items-center'>
        { children }
      </div>
    </button>
  </a>
}

const CircleButton:React.FC<IButtonProps> = ({
  children,
  className,
  whiteBg,
  ...restProps
}) => {
  const colors = whiteBg? 'bg-white hover:bg-gray-200' : 'bg-zoomBtnBg hover:bg-opacity-80';
  const cn = `w-10 h-10 transition duration-500 rounded-full flex flex-wrap justify-center place-content-center ${colors} ${className} disabled:bg-red-500`;
  return <button
    {...restProps}
    className={cn}
  >
    {children}
  </button>
}

const MapZoomButton:React.FC<IButtonProps> = ({
  children,
  className,
  disabled,
  whiteBg,
  ...restProps
}) => {
  const color = whiteBg ? 'bg-white' : 'bg-zoomBtnBg';
  const state = disabled ? 'opacity-50 pointer-events-none' : 'hover:bg-opacity-60';
  const cn = `w-10 h-10 transition duration-500 rounded-full flex flex-wrap justify-center place-content-center ${color} ${state} ${className} disabled:bg-red-500`;
  return <button
    {...restProps}
    disabled={disabled}
    className={cn}
  >
    {children}
  </button>
}

export {
  Button,
  NavLinkButton,
  LinkButton,
  CircleButton,
  MapZoomButton
}