import { useState, useEffect } from 'react';
import { Loader } from "./Loader";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { MapZoomButton } from './Button';

import { ZoomInIcon, ZoomOutIcon } from '../assets';
import parse from 'html-react-parser'

import _ from 'lodash';

export const ZoomItem = ({
  src,
  caption,
  credit,
}:{
  src: string,
  caption: string,
  credit: string
}) => {

  const wrapperStyle = {
    height: '100%',
    width: '100%',
  }
  
  const [, setImage] = useState<any>(null);
  const [showImage, setShowImage] = useState(false);
  const [initScale, setInitScale] = useState<number>(0.67);
  const [refresh, setRefresh] = useState<boolean>(false);
  const hasCaption = (caption && caption.length > 0) || (credit && credit.length > 0);

  useEffect(() => {
    window.addEventListener('resize', _.debounce(() => setRefresh(true), 100));
    return (() => {
      window.removeEventListener('resize', _.debounce(() => setRefresh(true), 100));
    })
  }, [])

  useEffect(() => {
    const image = new Image();
    image.onload = () => {
      setShowImage(true);
      setImage(image);

      const container = document.getElementById('container');
      const wd = (container?.clientWidth || 360);
      const ht = (container?.clientHeight || 360);
      const imgWd = image.width;
      const imgHt = image.height;
      const scaledWd = wd/imgWd;
      const scaledHt = ht/imgHt;
      if (scaledWd < scaledHt) {
        setInitScale(1);
        console.log('using wRatio');
      } else {
        console.log('usingHRatio', wd, imgWd, ht, imgHt);
        if (wd < ht) setInitScale(scaledHt/scaledWd);
        else setInitScale(scaledHt);
      }
      setRefresh(true);
    }
    image.src = src;
  }, [src])

  return <div className='w-full h-full' id='container'>
    <Loader isLoading={!showImage}>
      <>
        <TransformWrapper
          centerOnInit
          minScale={initScale}
          initialScale={initScale}
          maxScale={4}
          wheel={{ step: 0.1 }}
          pinch={{ step: 3 }}
        >
          {({
            zoomIn,
            zoomOut,
            instance,
            resetTransform
          }) => {

            if (refresh) {
              setRefresh(false);
              resetTransform();
            }

            const isMinScale = instance.transformState.scale === initScale;
            const isMaxScale = instance.transformState.scale === 4;

            return <div className='w-full h-full relative'>
              {
                showImage && <TransformComponent wrapperStyle={wrapperStyle}>
                  <img
                    src={src}
                    id='image'
                    className='w-full h-full'
                    alt={caption}
                  />
                </TransformComponent>
              }
              <div id='interface' className="absolute inset-y-0 right-0 h-full pointer-events-none z-20">
                <div className="h-full flex flex-wrap place-content-center p-4">
                  <div id='buttons' className='space-y-2 pointer-events-auto'>
                    <MapZoomButton
                      onClick={() => zoomIn(0.5, 600)}
                      className='bg-opacity-80'
                      disabled={isMaxScale}
                    >
                      <ZoomInIcon width='50px' height='50px' fill="white" strokeWidth={0}/>
                    </MapZoomButton>
                    <MapZoomButton
                      onClick={() => zoomOut(0.5, 600)}
                      className='bg-opacity-80'
                      disabled={isMinScale}
                    >
                      <ZoomOutIcon width='50px' height='50px' fill="white" strokeWidth={0} />
                    </MapZoomButton>
                  </div>
                </div>
              </div>
              {
                hasCaption && <div className='absolute bottom-0 inset-x-0 w-full h-auto bg-bgCaption z-30 px-10 py-8 space-y-2'>
                  <div className='text-white'>
                    {parse(caption)}
                  </div>
                  <div className='text-white text-xs'>
                    {parse(credit)}
                  </div>
                </div>
              }
            </div>
          }}
        </TransformWrapper>
      </>
    </Loader>
  </div>
}