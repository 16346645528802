import { NavLink, useLocation } from "react-router-dom";
import { useAtom } from "jotai";

import { NavButton, ExternalNavButton } from "./NavButton";
import { isMapAtom } from "../atoms";

import { Logo, WebsiteTitle } from "../assets";

const Navbar = () => {

	const [isMap] = useAtom(isMapAtom);

	// hover for map where it should hover above the map and has transparent background

	const location = useLocation();

	const hoverDiffClass = isMap ? 'bg-bgTransparent' : 'bg-darkGray';
	const mainDivClass = `absolute transition duration-200 grid grid-cols-3 content-center w-full px-12 h-32 ${hoverDiffClass}`

	const baseColor = isMap ? 'text-darkGray hover:text-darkGrayHover' : 'text-btnTextLight hover:text-white';
	const logoColor = isMap ? '#333333' : '#dac59d';

	return <>
		<div className="relative w-full z-20">
			<div className={mainDivClass}>
				<div className='flex flex-grow flex-wrap content-center'>
          <a href='https://capellahotels.com/en/capella-sydney' target='top_'>
						<div className={`transition duration-500 flex flex-wrap ${baseColor} font-bold uppercase items-center `}>
							<Logo fill='currentColor' width='200px' className='mr-3'/> 
						</div>
					</a>
				</div>
				<div className="font-bold flex flex-wrap flex-grow justify-center">
					<WebsiteTitle fill={logoColor} width='320px'/>
				</div>
				<div className='flex justify-end x-24 h-full'>
					<div className='w-fit flex flex-wrap content-center'>
						<NavButton darkBg={!isMap} to='./map' location={location.pathname}>
							Map
						</NavButton>
						<NavButton darkBg={!isMap} to='./stories' location={location.pathname}>
							Stories
						</NavButton>
						<NavButton darkBg={!isMap} to='./about' location={location.pathname}>
							About
						</NavButton>
					</div>
				</div>
			</div>
		</div>
	</>
}

export default Navbar;