import { atom } from 'jotai';
import { mobileWidth } from '../constants';

const isSmallAtom = atom<boolean>(window.innerWidth < mobileWidth);
const isLandscapeAtom = atom<boolean>(true);
const isMapAtom = atom<boolean>(true);

const preSelectedFilterAtom = atom<string|null>(null);

export {
  isSmallAtom,
  isMapAtom,
  isLandscapeAtom,
  preSelectedFilterAtom
};