import React, { useState, useEffect, Dispatch, useContext } from "react";
import { useNavigate, useParams, UNSAFE_NavigationContext, NavigationType } from "react-router-dom";
import { SetStateAction, useAtom } from "jotai";

import { isSmallAtom, isMapAtom, preSelectedFilterAtom } from "../atoms";
import {
	Content,
  ItemModal,
  StoryCatalog
} from "../components";
import { IButtonProps } from "../components/Button";
import { storiesAtom }  from "../atoms/data";
import { INode } from '../atoms/data';

const filters = [
  'All',
  'Farrer Place',
  'Education Building',
  'Lands Building',
]

const FilterButton = ({
  filterName,
  isSmall,
  isSelected,
  selectFilter
}: IButtonProps & {
  filterName: string;
  isSmall: boolean,
  isSelected?: boolean,
  selectFilter: (filter:string) => void;
}) => {
  const selectedClass = isSelected ? 'pointer-events-none text-white' : 'text-btnTextLight hover:text-white'
  return <div>
    <button
      onClick={() => selectFilter(filterName)}
      className={`transition duration-500 uppercase font-bold tracking-widest ${selectedClass} ${isSmall && 'py-1'}`}
    >
      {filterName}
    </button>
  </div>
}

const Filters = ({
  onFilterClick,
  filter,
  isSmall
}:{
  onFilterClick: Dispatch<SetStateAction<string>>,
  filter:string,
  isSmall: boolean,
}) => {
  return <div className={`animate-fadein bg-darkGray w-full text-center flex place-content-center ${isSmall || 'h-16'}`}>
    {
      isSmall ? <div className="w-5/6 block align-middle text-xs border-t py-4 border-btnTextLight">
        {
          filters.map((f: string) => {
            return <FilterButton
              key={f}
              selectFilter={() => onFilterClick(f)}
              isSmall
              filterName={f}
              isSelected={filter === f}
            />
          })
        }
      </div>
      : <div className="w-auto gap-10 border-t border-btnTextLight flex justify-between text-xs pt-4">
        {
          filters.map((f: string) => {
            return <FilterButton
              key={f}
              selectFilter={() => onFilterClick(f)}
              isSmall
              filterName={f}
              isSelected={filter === f}
            />
          })
        }
      </div>
    }
  </div>
}

const Stories:React.FC = () => {

  const { id } = useParams();

  const [isSmall] = useAtom(isSmallAtom);
  const [, setIsMap] = useAtom(isMapAtom);
  const [storiesData] = useAtom(storiesAtom);

  const [preSelectedFilter, setPreSelectedFilter] = useAtom(preSelectedFilterAtom);
  const [filter, setFilter] = useState<string>(filters[0]);
  const [selectedNodeId, setSelectedNodeId] = useState<string|undefined>(id);
  const [showItem, setShowItem] = useState<boolean>(id !== undefined);

  const navigate = useNavigate();
  window.onpopstate = () => {
    if (!id && showItem) {
      setShowItem(false);
      setSelectedNodeId(undefined);
    } else if (id && !showItem) {
      setSelectedNodeId(id);
    }
  }

  useEffect(() => {
    setIsMap(false);
    if (preSelectedFilter !== null) {
      setFilter(preSelectedFilter);
      setPreSelectedFilter(null);
    }
  }, [])

  useEffect(() => {
    if (selectedNodeId !== undefined) setShowItem(true);
    else setShowItem(false);
  }, [selectedNodeId])

  useEffect(() => {
    document.body.style.overflow = showItem ? 'hidden' : 'unset';
  }, [showItem])

  const setModalState = (state: boolean) => {
    if (!state) {
      setSelectedNodeId(undefined);
      navigate(`/stories`);
    }
    setShowItem(false);
  }

  const selectNodeId = (nodeId:string | undefined) => {
    if (!nodeId) navigate(`/stories`);
    else navigate(`/stories/${nodeId}`);
    setSelectedNodeId(nodeId);
  }

  const filteredData = filter === 'All' ? storiesData : storiesData.reduce((arr:INode[], story:INode) => {
    if (story.location === filter) return [...arr, story];
    return arr;
  }, [])

  const currentNode = selectedNodeId ? filteredData.find((node:INode) => {
    return String(node.id) === String(selectedNodeId)
  }) : null;

	return <>
    <div className='w-full min-h-full bg-darkGray'>
    <Content>
      <Filters onFilterClick={setFilter} filter={filter} isSmall={isSmall} />
      <StoryCatalog
        filteredData={filteredData}
        setNodeId={selectNodeId}
      />
    </Content>
    </div>
    {
      currentNode && <ItemModal
        show={showItem}
        setShow={setModalState}
        data={currentNode}
        isSmall={isSmall}
        isMapModal={false}
      />
    }
  </>

}

export default Stories;